<template>
  <main class="OrdersChangeStatus">
    <TheSubHeader
      icon="export-alt"
      title="Mudar Status"
      page-title-tag="Orders Change Status"
      type="filter"
      :actions="['see', 'import', 'export', 'addnew']"
      organizer-gap="1rem"
      organizer-columns="240px 1.5fr 1.5fr 1.5fr"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.incomingOrderButton"
          icon="package-request"
          color="dark"
          label="Recebimento de físico"
          :track="trackSubheaderButtonString('Orders Change Status', 'Orders Incoming')"
          @click="goToIncomingOrders"
        />
      </template>
      <template #filter>
        <!-- Stores -->
        <BaseTreeSelect
          v-model="filters.storeLabel"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          :select-options="null"
          :fetch-options="'stores/fetchStoresWithInfoAsync'"
          @input="storeStatus()"
        />
        <!-- Status -->
        <BaseTreeSelect
          v-model="filters.storeStatus"
          name="Status"
          label="Status"
          placeholder="Selecione"
          :read-only="disabledFields.storeStatus"
          :select-options="statusOnly"
        />
        <!-- Delete Status -->
        <BaseTreeSelect
          v-model="filters.deleteLastStatus"
          name="Apagar último status?"
          label="Apagar último status?"
          placeholder="Selecione"
          :auto-select="true"
          :clearable="false"
          :select-options="deleteLastStatusOptions"
        />
        <!-- Archive -->
        <InputFile
          ref="inputFile"
          v-model="filters.orderFile"
          label="Planilha"
          btn-label="Escolha o arquivo"
          accept-files=".xlsx, .xls, .zip, .xml"
        />
        <!-- Import -->
        <BaseButton
          label="Importar arquivo"
          theme="icon-left"
          filled
          color="primary-base"
          text-color="white"
          icon="download"
          :disabled="disabledFields.orderImported"
          @click="ordersStatusImport"
        />
      </template>
    </TheSubHeader>
    <div class="OrdersChangeStatus__Wrapper">
      <div class="OrdersChangeStatus__Content">
        <div class="OrdersChangeStatus__Filter">
          <h1 class="OrdersChangeStatus__Filter__Title">
            HISTÓRICO DE MUDANÇA DE STATUS
          </h1>
          <!-- Filter Table -->
          <div class="OrdersChangeStatus__Filter__Content">
            <!-- Period -->
            <BaseDatePicker
              v-model="formattedDateRange"
              label="Período"
              @input="search()"
            />
            <!-- Stores -->
            <BaseTreeSelect
              v-model="historic.storeLabel"
              name="Lojista"
              label="Lojista"
              placeholder="Selecione"
              :select-options="null"
              :fetch-options="'stores/fetchStoresWithInfoAsync'"
              @input="search()"
            />
            <!-- Status -->
            <BaseTreeSelect
              v-model="historic.historicSelected"
              name="Status"
              label="Status"
              placeholder="Selecione"
              :select-options="historicOptions"
              @input="search()"
            />
          </div>

          <!-- Table -->
          <div class="OrdersChangeStatus__Table">
            <DataTable
              :header="importedStatusOrders.data.headers"
              :table="importedStatusOrdersWithLoading"
              is-sticky
              @see="errorsModal"
              @download="downloadExcel"
            />
            <Pagination @page="search()" @paginate="search()" />
          </div>
        </div>
      </div>
    </div>
    <ErrorList
      v-if="showErros"
      :list="errorStatusSort"
      class="OrdersChangeStatus__ErrorList"
      @close="closeErrorList"
    />
  </main>
</template>

<script>
import { orders } from '@/api'
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker } from '@/components/atoms'
import {
  InputFile,
  Pagination,
  ErrorList,
  DataTable,
} from '@/components/molecules'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'OrdersChangeStatus',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    ErrorList,
    InputFile,
    Pagination,
  },

  data() {
    return {
      //Filters
      filters: {
        storeLabel: null,
        storeStatus: null,
        deleteLastStatus: null,
        orderFile: null,
      },

      //Historic
      historic: {
        dateRange: {
          init: null,
          end: null,
        },
        storeLabel: null,
        historicSelected: null,
      },

      //Select Options
      deleteLastStatusOptions: [
        { id: false, name: 'Não' },
        { id: true, name: 'Sim' },
      ],

      historicOptions: [
        { id: 0, name: 'Todos' },
        { id: 1, name: 'Andamento' },
        { id: 2, name: 'Concluída' },
        { id: 3, name: 'Concluída com erros' },
      ],

      //Errors
      showErros: false,

      //Helpers
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,
    }
  },

  computed: {
    ...mapState({
      importedStatusOrders: (state) => state.orders.importStatusOrders,
      statusOnly: (state) => state.orders.statusOnly,
      page: (state) => state.pagination.page,
      paginate: (state) => state.pagination.paginate,
      permissions: (state) => state.auth.permissions
    }),

    ...mapGetters({
      errorStatusSort: 'orders/errorStatusSort',
      importedStatusOrdersWithLoading: 'orders/importedStatusOrdersWithLoading',
    }),

    //Disabled Fields
    disabledFields() {
      return {
        //Filters
        storeStatus: !this.filters.storeLabel || this.statusOnly.length === 0,
        //Buttons
        orderImported:
          !this.filters.storeLabel ||
          !this.filters.storeStatus ||
          !this.filters.orderFile,
      }
    },

    //Filters
    formattedDateRange: {
      get: function() {
        return [this.historic.dateRange.init, this.historic.dateRange.end]
      },
      set: function(newValue) {
        this.historic.dateRange.init = newValue[0]
        this.historic.dateRange.end = newValue[1]
      },
    },
  },
  mounted() {
    this.search()
  },
  methods: {
    ...mapActions({
      //Filters
      getStatusOnly: 'orders/getStatusOnly',
      //Table
      fetchImportStatusOrders: 'orders/fetchImportStatusOrders',
      setPagination: 'pagination/setPagination',
      clearPagination: 'pagination/clearPagination',
      //Import
      importStatusOrders: 'orders/importStatusOrders',
      //Errors
      fetchImportStatusErrors: 'orders/fetchImportStatusErrors',
      //Helpers
      setLoading: 'loading/setLoading',
    }),

    //Filters
    storeStatus() {
      this.filters.storeStatus = null
      if (this.filters.storeLabel) {
        this.getStatusOnly({ id: this.filters.storeLabel.id })
      }
    },

    //Import
    async ordersStatusImport() {
      let formData = new FormData()
      formData.append('store_id', this.filters.storeLabel.id)
      formData.append('store_status_id', this.filters.storeStatus.id)
      formData.append('deleteLastStatus', this.filters.deleteLastStatus.id)
      formData.append('file', this.filters.orderFile)

      const res = await this.importStatusOrders(formData)
      this.resetFile()

      if (!res.trace) {
        this.historic.historicSelected = null
        this.filters.deleteLastStatus = { id: false, name: 'Não' }
        this.resetSearch()
      }
    },

    //Search
    search(loading = true) {
      if (loading) this.setLoading(true)

      const params = {
        obj: {
          date_start: this.historic.dateRange.init,
          date_end: this.historic.dateRange.end,
          store_id: this.historic.storeLabel
            ? this.historic.storeLabel.id
            : null,
          orders_import_status_id: this.historic.historicSelected
            ? this.historic.historicSelected.id
            : null,
        },
        id: this.transporterId,
        paginate: this.paginate,
        page: this.page,
      }

      this.fetchImportStatusOrders(params).then(() => {
        this.setPagination(this.importedStatusOrders)
        this.checkFetchImportStatusOrders()
      })
    },

    checkFetchImportStatusOrders() {
      if (
        JSON.stringify(this.importedStatusOrders.data.content).indexOf(
          'Andamento'
        ) > 0 &&
        this.$route.name === 'ordersChangeStatus'
      ) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.search(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.importedStatusOrders)
        } else {
          clearTimeout(this.time)
          this.setPagination(this.importedStatusOrders)
        }
      }
    },

    //Handlers
    downloadExcel(data) {
      this.downloadImported(data.id)
    },
    async downloadImported(id) {
      this.setLoading(true)
      await orders.importedStatusOrders(
        id,
        (r) => r,
        (e) => e,
        () => this.setLoading(false)
      )
    },

    //Reset
    resetSearch() {
      this.historic.dateRange = {init: null, end: null }
      this.historic.storeLabel = null
      this.historic.historicSelected = null
      this.clearPagination().then(() => this.search())
    },

    resetFile() {
      this.filters.orderFile = null
      this.$refs.inputFile.reset()
    },

    //Errors
    errorsModal(data) {
      this.fetchImportStatusErrors(data.id)
      this.showErros = true
    },
    closeErrorList() {
      this.showErros = false
    },

    //Helpers
    goToIncomingOrders() {
      this.$router.push('/orders/incoming')
    },
  },
}
</script>
<style lang="scss" scoped>
.OrdersChangeStatus {
  &__Wrapper {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: '. main .';
    grid-column-gap: 1rem;
  }

  &__Content {
    grid-area: main;
    margin-bottom: 50px;
  }

  &__Filter {
    padding-top: 30px;
    &--able {
      pointer-events: auto;
      opacity: 1;
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &__Title {
      font-weight: $font-weight-bold;
      font-style: normal;
      font-size: $font-size-mini;
      color: $color-neutral-stronger;
      margin-bottom: 20px;
    }
    &__Content {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      align-items: end;
      margin-bottom: 50px;
      @media #{$mobile-view} {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  &--disable {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}
</style>
<style lang="scss">
.OrdersChangeStatus {
  .Organizer__filter {
    display: flex;
    align-items: flex-end;
  }
}
</style>
